var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{},[_c('table',{staticClass:"w-full text-sm font-normal rounded-t-lg border-b border-l border-r border-opacity-20 border-tasqNeutral50",staticStyle:{"border-collapse":"separate","border-spacing":"0","border-top-left-radius":"0.5rem","border-top-right-radius":"0.5rem"}},[_vm._m(0),_c('tr',{staticClass:"border-b border-opacity-20"},[_c('td',{staticClass:"text-xs px-2 py-2 border-b border-r border-opacity-20 text-tasqNeutral50 border-tasqNeutral500"},[_vm._v(" "+_vm._s(_vm.setpointRecord.decided_by)+" Open "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            theme: 'tasq-tooltip',
            content: 'Use when static pressure is reliable enough to trigger an open event',
          }),expression:"{\n            theme: 'tasq-tooltip',\n            content: 'Use when static pressure is reliable enough to trigger an open event',\n          }",modifiers:{"bottom":true}}],staticClass:"material-icons mx-2 text-white",staticStyle:{"cursor":"pointer","vertical-align":"middle","font-size":"18px","color":"rgba(255, 255, 255, 0.3)"}},[_vm._v(" info ")])]),_c('td',{staticClass:"text-xs px-2 py-2 border-b border-r border-opacity-20 text-tasqNeutral50 border-tasqNeutral500"},[_vm._v(" "+_vm._s(_vm.roundToInteger(_vm.setpointRecord.last_week_open))+" ")]),_c('td',{staticClass:"text-xs px-2 py-2 border-b border-opacity-20 text-tasqNeutral50 border-tasqNeutral500"},[(_vm.setpointRecord.decided_by === 'TP - LP')?_c('span',[_vm._v(" "+_vm._s(_vm.roundToInteger(_vm.setpointRecord.rec_open)))]):_vm._e(),(_vm.setpointRecord.decided_by === 'CP - LP')?_c('span',[_vm._v(" "+_vm._s(_vm.roundToInteger(_vm.setpointRecord.rec_open_cp_lp)))]):_vm._e(),(_vm.setpointRecord.decided_by === 'Tubing Pressure')?_c('span',[_vm._v(" "+_vm._s(_vm.roundToInteger(_vm.setpointRecord.rec_open_tp)))]):_vm._e()])]),_c('tr',[_c('td',{staticClass:"text-xs px-2 py-2 border-b border-r border-opacity-20 text-tasqNeutral50 border-tasqNeutral500"},[_vm._v(" TP Open "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
            theme: 'tasq-tooltip',
            content: 'Use if static pressure is not reliable enough to trigger an open event',
          }),expression:"{\n            theme: 'tasq-tooltip',\n            content: 'Use if static pressure is not reliable enough to trigger an open event',\n          }",modifiers:{"bottom":true}}],staticClass:"material-icons mx-2 text-white",staticStyle:{"cursor":"pointer","vertical-align":"middle","font-size":"18px","color":"rgba(255, 255, 255, 0.3)"}},[_vm._v(" info ")])]),_c('td',{staticClass:"text-xs px-2 py-2 border-b border-r border-opacity-20 text-tasqNeutral50 border-tasqNeutral500"},[_vm._v(" "+_vm._s(_vm.roundToInteger(_vm.setpointRecord.last_week_open_tp))+" ")]),_c('td',{staticClass:"text-xs px-2 py-2"},[_vm._v(" "+_vm._s(_vm.roundToInteger(_vm.setpointRecord.rec_open_tp))+" ")])])]),(!_vm.message)?_c('div',{staticClass:"flex justify-between flex-row my-5 mt-10 px-0.5 gap-2"},[_c('div',{staticClass:"text-base border w-48 border-accentSuccess200 hover:text-tasqNeutral1000 hover:bg-opacity-100 cursor-pointer bg-opacity-10 bg-accentSuccess200 font-semibold rounded uppercase p-2 text-tasqNeutral50 text-center",on:{"click":function($event){return _vm.updateSetpoints('Accept')}}},[_vm._v(" Accept "),_c('span',{staticClass:"mx-1"},[_vm._v(" 👍")])]),_c('div',{staticClass:"text-base border w-48 border-accentError200 hover:bg-opacity-100 bg-opacity-10 bg-accentError200 font-semibold hover:text-tasqNeutral1000 cursor-pointer rounded uppercase p-2 text-tasqNeutral50 text-center",on:{"click":function($event){return _vm.updateSetpoints('Reject')}}},[_vm._v(" Reject "),_c('span',{staticClass:"mx-1"},[_vm._v(" 👎")])])]):_c('div',{staticClass:"text-center font-semibold my-8 text-base text-tasqNeutral200"},[_vm._v(" "+_vm._s(_vm.message)+" ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"text-white text-sm font-semibold font-inter bg-tasqNeutral700"},[_c('th',{staticClass:"text-xs border-r border-opacity-20 text-left px-2 rounded-tl-lg"},[_vm._v(" Type ")]),_c('th',{staticClass:"text-xs border-r border-opacity-20 text-left px-2 py-2"},[_vm._v("Current")]),_c('th',{staticClass:"text-xs text-left mb-5 px-2 py-2 rounded-tr-lg"},[_vm._v(" Rec "),_c('span',{staticClass:"block text-xxs font-thin text-gray008"})])])
}]

export { render, staticRenderFns }